





































































































































































.support-modal-upload{
  width: 100%;

  .el-upload, .el-upload-dragger {
    width: 100%;
  }
}

