











































































































































































@import "@/assets/css/project-variables";
.smz-home {
  color: #141414 !important;

  .home-have-problem-text{
    margin-top: 36px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #145969;
  }

  &__general {
    display: flex;
    justify-content: space-between;

    &_name {
      font-weight: bold;
      font-size: 24px;
    }
  }

  &__block-body {
    display: flex;
    gap: 50px;
  }

  &__block-body-buttons {
    width: 680px;
    padding-top: 14px;

    a {
      text-decoration: none;
    }

    .el-card {
      margin-bottom: 20px;

      &__body{
        padding: 10px;
      }

      .card-icon {
        padding: 0 0 0 10px;
        font-size: 36px;
        opacity: 0.5;
      }

      .card-text {
        width: 100%;
        text-align: center;
      }

      .card-text.one-line {
        padding-top: 14px;
        text-transform: uppercase;
      }
    }

  }

  &__block-header {
    color: $--color-primary !important;
    text-transform: uppercase;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    padding: 10px 0 16px 0;
  }

  &__block-name {
    font-size: 20px;
    font-weight: bold;
    padding: 10px 0;
  }

  &__block-desc {
  }
}
