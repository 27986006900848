




















































































@import "@/assets/css/project-variables";

.file-link {
  margin-right: 5px;
  cursor: pointer;
  //text-decoration: underline;
  color: $--color-primary;
}
